// KrispCall Component

import { useEffect, useState } from "react";

import { EmailInbox, VerificationIcon } from "src/krispcall/assets";
import OtpInputField from "src/krispcall/components/molecules/fields/v3/OtpInputField";
import { Form as CustomForm, useForm } from "src/krispcall/components/organisms/form";
import Button from "src/krispcall/components/atoms/button/v3/Button";
import { useGetRecaptchaV3Token } from "src/krispcall/components/organisms/recaptcha/useGetRecaptchaV3Token";
import { CaptchaProvider } from "src/krispcall/utils/CaptchaProvider";

import * as UserBackend from "src/backend/UserBackend";
import * as Setting from "src/Setting";

import { SignupEmailVerificationWrapper } from "../Styles";
import { OtpSchema } from "../schema";
import { CaptchaModalCustom } from "src/krispcall/components/organisms";

export const OtpForm = ({
  email,
  application,
  handleSubmit,
  updateFormValues,
  state,
  isForgotPassword,
  isLoading,
}) => {
  const [visible, setVisible] = useState(false);
  const [buttonLeftTime, setButtonLeftTime] = useState(0);
  const [resentOtp, setResentOTP] = useState(undefined);
  const [resendOtpButtonLoading, setResendOtpButtonLoading] = useState(false);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const pageTitle = isForgotPassword ? "Check your email" : "Email Verification";
  const pageSubTitle = isForgotPassword ? `Please enter the 6 digit code sent to your email ${email}` : "Please enter the 6 digit code sent to your email for verification.";
  let timer = undefined;
  const provider = CaptchaProvider(application);

  const handleCountDown = (leftTime = 60) => {
    let leftTimeSecond = leftTime;
    setButtonLeftTime(leftTimeSecond);
    const countDown = () => {
      leftTimeSecond--;
      setButtonLeftTime(leftTimeSecond);
      if (leftTimeSecond === 0) {
        setResentOTP(false);
        return;
      }
      timer = setTimeout(countDown, 1000);
    };
    timer = setTimeout(countDown, 1000);
  };

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    // if not forgot password page then start countdown when page loads
    if (resentOtp === undefined && !isForgotPassword) {
      handleCountDown();
    }
  }, [resentOtp]);

  const handleOk = async(captchaType, captchaToken, clintSecret) => {
    setVisible(false);

    setResendOtpButtonLoading(true);
    const onButtonClickArgs = [
      state.email,
      "email",
      Setting.getApplicationName(application),
    ];
    const [countryCode] = [undefined];
    const method = isForgotPassword ? "forget" : "signup";

    UserBackend.sendCode(
      captchaType,
      captchaToken,
      clintSecret,
      method,
      countryCode,
      ...onButtonClickArgs
    ).then((res) => {
      handleCountDown(60);
      setResendOtpButtonLoading(false);
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const FORM_ID = "email-verification-form";
  const form = useForm({
    schema: OtpSchema,
    mode: "onChange",
  });

  const {
    getValues,
    formState: { isValid },
  } = form;

  async function handleLogin() {
    const values = getValues();
    updateFormValues("emailCode", values.emailCode);
    if (isForgotPassword) {
      values.application = application.name;
      values.organization = application.organization;
      values.code = values.emailCode;
      updateFormValues("code", values.emailCode);
      await handleSubmit("step2", values);
    } else {
      await handleSubmit(values);
    }
  }

  async function handleCaptcha() {
    if (state.captchaType !== "reCAPTCHA v3-Standard") {
      setVisible(true);
    } else {
      const captchaToken = await useGetRecaptchaV3Token(state.captchaSiteKey);
      handleOk(provider.type, captchaToken, provider.clientSecret);
    }
  }

  useEffect(() => {
    if (isForgotPassword) {
      handleCaptcha();
    }
  }, []);

  return (
    <>
      <div className="flex h-full items-center justify-center">
        <SignupEmailVerificationWrapper>
          <div className="signup-icon-circle"><img src={isForgotPassword ? EmailInbox : VerificationIcon} /></div>
          <span className="signup-verification-title">{pageTitle}</span>
          <span className="signup-verification-sub-title">{pageSubTitle}</span>
          <CustomForm
            form={form}
            id={FORM_ID}
            fieldSetClassName="flex flex-col gap-4"
            >
              <OtpInputField
                name="emailCode"
                id="emailCode"
                containerStyle="my-8"
                autoFocus
              />

              <Button onClick={handleLogin} loading={isLoading} disabled={!isValid} form={FORM_ID} htmlType="button" variant="primary">Verify Email</Button>
              <Button loading={resendOtpButtonLoading} variant="secondary" onClick={() => handleCaptcha()} disabled={(buttonLeftTime > 0 && !resendOtpButtonLoading)}>Resend Verification Code {buttonLeftTime > 0 && `(${buttonLeftTime})`}</Button>
          </CustomForm>
        </SignupEmailVerificationWrapper>
      </div>
      {
        state.captchaType !== "reCAPTCHA v3-Standard" ?
        <CaptchaModalCustom
          owner={application.owner}
          name={application.name}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          isCurrentProvider={false}
        /> : ""
      }

    </>
  );
};
