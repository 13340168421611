// this was required since, hooks cannot be directly used in class components
import { useEffect } from "react";

import { Form, useForm } from "src/krispcall/components/organisms/form";

import { schema } from "../schema";
import { newPasswordSchema } from "../newPasswordSchema";

const ForgotPasswordFormWrapper = ({children, application, setState, onFinish, current}) => {
  const FORM_ID = "forgot-password-form";
  const RESET_PASSWORD_STEP = 2;

  const form = useForm({
    schema: current === RESET_PASSWORD_STEP ? newPasswordSchema : schema,
    mode: "onChange",
  });

  const {
    watch,
    setError,
    formState: { isValid },
  } = form;
  const password = watch("password");
  const confirm = watch("confirm");

  useEffect(() => {
    setState({ isValid: isValid });
  }, [isValid]);

  useEffect(() => {
    if (confirm && password && confirm !== password) {
      setError("confirm", { type: "custom", message: "Password didn't match" });
    }
  }, [confirm, password, setError]);

  function handleSubmit(values) {
    values.application = application.name;
    values.organization = application.organization;
    const step = current === 2 ? "step3" : "step1";
    onFinish(step, values);
  }

  return (
    <Form form={form} id={FORM_ID} onSubmit={handleSubmit} fieldSetClassName="flex flex-col gap-4 w-80">
      {children}
    </Form>
  );
};
export default ForgotPasswordFormWrapper;
