import { forwardRef, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Progress } from "antd";

import { useFieldError } from "src/krispcall/components/organisms/form";
import { PasswordField as PasswordFieldAtom } from "src/krispcall/components/atoms/field/v3";
import { LabelV3 } from "src/krispcall/components/atoms";

import { useCheckPasswordStrength } from "./useCheckPasswordStrength";

const PasswordInputField = forwardRef(function PasswordTextInput(props, ref) {
  const { id = "", label, ...rest } = props;

  const { watch } = useFormContext();
  const formValues = watch();

  const { fieldStatus, errorElement } = useFieldError({
    name: rest?.name,
  });

  const { checkPasswordStrength } = useCheckPasswordStrength();
  const { percent, color } = checkPasswordStrength(formValues.password);

  return (
    <div>
      {label && (
        <LabelV3 id={id || rest?.name} disabled={rest?.disabled} isRequired>
          {label}
        </LabelV3>
      )}
      <Controller
        name={rest?.name}
        control={rest?.control}
        render={({ field }) => {
          return (
            <PasswordFieldAtom
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              id={id || rest?.name}
              ref={ref}
              value={field.value}
              status={rest?.status || fieldStatus}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest}
            />
          );
        }}
      />
      {errorElement}
      {rest?.enableStrengthChecker && (
        <>
          <Progress
            id="password-progress"
            percent={percent}
            showInfo={false}
            strokeColor={color}
          />
          <span className="text-xs text-text-300">
            Use 8 or more characters with a mix of upper case, lower case
            letters, numbers & symbols.
          </span>
        </>
      )}
    </div>
  );
});

export default memo(PasswordInputField);
