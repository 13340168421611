const ArrowLeft = ({ className }) => {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33341 10L16.6667 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.19373 3.61613L3.71635 9.0935C3.59673 9.21214 3.50173 9.35332 3.43694 9.50897C3.37206 9.66453 3.33869 9.83141 3.33869 9.99996C3.33869 10.1685 3.37206 10.3354 3.43694 10.491C3.50173 10.6466 3.59673 10.7878 3.71635 10.9064L9.19373 16.3838"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
};

export default ArrowLeft;
