// copied from ForgetPage for customization.

import React from "react";

import { Select } from "antd";
import i18next from "i18next";
import {withRouter} from "react-router-dom";

import CustomGithubCorner from "src/common/CustomGithubCorner";
import { createMarkup } from "src/krispcall/utils/markup";
import { LockIcon, SuccessTick, VerificationIcon } from "src/krispcall/assets/";
import Button from "src/krispcall/components/atoms/button/v3/Button";

import * as UserBackend from "src/backend/UserBackend";
import * as AuthBackend from "src/auth/AuthBackend";
import * as ApplicationBackend from "src/backend/ApplicationBackend";
import * as Util from "src/auth/Util";
import * as Setting from "src/Setting";

import { CrispChat } from "../../organisms";
import { CustomThemeContextProvider } from "src/krispcall/context/CustomThemeContext";
import { SignupLeftWrapper } from "../signup/Styles";
import ForgotPasswordFormWrapper from "./common/ForgotPasswordFormWrapper";
import { InputField, PasswordField } from "../../molecules/fields/v3";
import { ArrowLeft } from "../../../assets";
import { OtpForm } from "../signup/common/OtpForm";

const {Option} = Select;

class ForgotPageCustom extends React.Component {
  constructor(props) {
    super(props);
    this.updateFormValuesRef = this.updateFormValues.bind(this);
    this.setEmailOtpCodeRef = this.setEmailOtpCode.bind(this);
    this.onFormFinishRef = this.onFormFinish.bind(this);
    this.state = {
      classes: props,
      applicationName: props.applicationName ?? props.match.params?.applicationName,
      msg: null,
      name: "",
      username: "",
      phone: "",
      email: "",
      dest: "",
      isVerifyTypeFixed: false,
      verifyType: "", // "email", "phone"
      current: 0,
      code: "",
      resend: true,
      countDown: 0,
      newPassword: "",
      oldPassword: "",
      buttonLoading: false,
    };

    this.form = React.createRef();
    this.setStateRef = this.setState.bind(this);
  }

  componentDidMount() {
    if (this.getApplicationObj() === undefined) {
      if (this.state.applicationName !== undefined) {
        this.getApplication();
      } else {
        Setting.showMessage("error", i18next.t("forget:Unknown forget type") + ": " + this.state.type);
      }
    }
    if (this.state.current === 1) {
      this.handleCountDown();
    }
  }

  getApplication() {
    if (this.state.applicationName === undefined) {
      return;
    }

    ApplicationBackend.getApplication("admin",
      this.state.applicationName
      )
      .then((res) => {
        if (!localStorage.getItem("params")) {
          Setting.showMessage("error", "Sesion Expired! Couldn't find Application!");
          window.location.href = res.data.homepageUrl;
        }
        if (res.status === "error") {
          Setting.showMessage("error", res.msg);
          return;
        }
        this.onUpdateApplication(res.data);
      });
  }
  getApplicationObj() {
    return this.props.application;
  }

  onUpdateApplication(application) {
    this.props.onUpdateApplication(application);
  }

  updateFormValues(field, value) {
    this.setState({ [field]: value });
  }

  isValidateForm() {
    if (this.state.newPassword.length < 2 || this.state.oldPassword < 2 || (this.state.oldPassword !== this.state.newPassword)) {
      return false;
    }
    return true;
  }

  onFinish(values) {
    this.updateFormValues("buttonLoading", true);
    values.username = this.state.name;
    values.userOwner = this.getApplicationObj()?.organizationObj.name;
    UserBackend.setPassword(
      values.userOwner,
      values.username,
      "",
      values?.confirm,
      this.state.code
    ).then(res => {
      this.updateFormValues("buttonLoading", false);
      if (res.status === "ok") {
        this.setState({current: 3});
      } else {
        Setting.showMessage("error", res.msg);
      }
    });
  }

  onFormFinish(name, forms) {
    switch (name) {
    case "step1":
      const username = forms.username;
      this.updateFormValues("buttonLoading", true);

      AuthBackend.getEmailAndPhone(forms.organization, username)
        .then((res) => {
          this.updateFormValues("buttonLoading", false);
          if (res.status === "ok") {
            const phone = res.data.phone;
            const email = res.data.email;

            if (!phone && !email) {
              Setting.showMessage("error", "no verification method!");
            } else {
              this.setState({
                name: res.data.name,
                phone: phone,
                email: email,
              });

              const saveFields = (type, dest, fixed) => {
                this.setState({
                  verifyType: type,
                  isVerifyTypeFixed: fixed,
                  dest: dest,
                });
              };

              switch (res.data2) {
              case "email":
                saveFields("email", email, true);
                break;
              case "phone":
                saveFields("phone", phone, true);
                break;
              case "username":
                phone !== "" ? saveFields("phone", phone, false) : saveFields("email", email, false);
              }

              this.setState({
                current: 1,
              });
            }
          } else {
            Setting.showMessage("error", res.msg);
          }
        });
      break;
    case "step2":
      this.updateFormValues("buttonLoading", true);
      UserBackend.verifyCode({
        application: forms.application,
        organization: forms.organization,
        username: this.state.name,
        name: this.state.name,
        code: forms.code,
        type: "login",
      }).then(res => {
        this.updateFormValues("buttonLoading", false);
        if (res.status === "ok") {
          this.setState({current: 2});
        } else {
          Setting.showMessage("error", res.msg);
        }
      });
      break;
    case "step3":
      this.onFinish(forms);
      break;
    default:
      break;
    }
  }

  onFinishFailed(values, errorFields) {}

  renderOptions() {
    const options = [];

    if (this.state.phone !== "") {
      options.push(
        <Option key={"phone"} value={this.state.phone} >
          &nbsp;&nbsp;{this.state.phone}
        </Option>
      );
    }

    if (this.state.email !== "") {
      options.push(
        <Option key={"email"} value={this.state.email} >
          &nbsp;&nbsp;{this.state.email}
        </Option>
      );
    }

    return options;
  }

  setEmailOtpCode(otp) {
    this.updateFormValues("code", otp);
  }

  handleCountDown = (leftTime = 60) => {
    let leftTimeSecond = leftTime;
    this.updateFormValues("countDown", leftTimeSecond);
    const countDown = () => {
      leftTimeSecond--;
      this.updateFormValues("countDown", leftTimeSecond);
      if (leftTimeSecond === 0) {
        this.updateFormValues("resend", false);
        return;
      }
      setTimeout(countDown, 1000);
    };
    setTimeout(countDown, 1000);
  };

  handleGoBack(oAuthParams) {
    const { history } = this.props;
      history.push(`/login/oauth/authorize?client_id=${oAuthParams?.clientId}&response_type=${oAuthParams?.responseType}&redirect_uri=${oAuthParams?.redirectUri}&scope=${oAuthParams?.scope}&state=${oAuthParams?.state}&code_challenge=${oAuthParams?.codeChallenge}&code_challenge_method=${oAuthParams?.challengeMethod}`);
  }

  renderForm(application) {
    const FORM_ID = "forgot-password-form";
    const oAuthParams = localStorage.getItem("params") ? JSON.parse(atob(localStorage.getItem("params"))) : Util.getOAuthGetParameters();

    return (
      <div>
        {/* STEP 1: input username -> get email & phone */}
        {
          this.state.current === 0 ?
          <>
            <div className="flex flex-col mb-6 justify-left">
              <img className="h-20 w-20 mb-6" src={LockIcon} alt="krispcall-lock-icon" />
              <span className="text-text-600 font-medium text-2xl">Forgot Password?</span>
              <span className="text-text-400 text-15">We got your back. Enter you registered email address to get a password reset link</span>
            </div>
            <InputField
              label={"Email Address"}
              name="username"
              id="username"
              autoFocus
            />
            <Button
              form={FORM_ID}
              htmlType="submit"
              variant="primary"
              loading={this.state.buttonLoading}
              className="w-full mt-6">
              {i18next.t("forget:Send Code")}
            </Button>
            <Button
              variant="secondary"
              onClick={() => this.handleGoBack(oAuthParams)}
              icon={<ArrowLeft className={"h-5"} />}
              className="w-full mt-3.5">
              {i18next.t("forget:Go Back to Sign In")}
            </Button>
          </> : null
        }

        {/* STEP 2: verify email or phone */}
        {
          this.state.current === 1 ?
          <div>
              <OtpForm
                isForgotPassword={true}
                email={this.state.email}
                application={application}
                updateFormValues={this.updateFormValuesRef}
                state={this.state}
                handleSubmit={this.onFormFinishRef}
                isLoading={this.state.buttonLoading}
              />
          </div> : null
        }

        {/* STEP 3 */}
        {
          this.state.current === 2 ?
          <div>
            <div className="flex flex-col mb-6 justify-left">
              <div className="h-20 w-20 rounded-full bg-background-200 mb-6 flex items-center justify-center">
                <img className="h-12" src={VerificationIcon} alt="krispcall-lock-icon" />
              </div>
              <span className="text-text-600 font-medium text-2xl">Reset Password</span>
              <span className="text-text-400 text-15">Strong passwords include numbers, letters and punctuation marks</span>
            </div>
            <div className="flex flex-col gap-5">
              <PasswordField
                label="Password"
                name="password"
                id="password"
                enableStrengthChecker={true}
              />

              <PasswordField
                label="Confirm Password"
                name="confirm"
                id="confirm"
                enableStrengthChecker={false}
              />
            </div>

              <Button
                htmlType="submit"
                variant="primary"
                className="w-full mt-6"
                loading={this.state.buttonLoading}>
              Reset Password
            </Button>
          </div> : null
        }

        {/* STEP 4 */}
        {
          this.state.current === 3 ?
          <div>
            <div className="flex flex-col mb-6 justify-left">
              <div className="h-20 w-20 rounded-full bg-background-200 mb-6 flex items-center justify-center">
                <img className="h-12" src={SuccessTick} alt="krispcall-lock-icon" />
              </div>
              <span className="text-text-600 font-medium text-2xl">Password Changed</span>
              <span className="text-text-400 text-15">Your password has been changed successfully</span>
            </div>
            <Button className="mt-6" variant="primary" onClick={() => this.handleGoBack(oAuthParams)}>Back to Login</Button>
          </div> : null
        }
      </div>
    );
  }

  stepBack() {
    if (this.state.current > 0) {
      this.setState({
        current: this.state.current - 1,
      });
    } else if (this.props.history.length > 1) {
      this.props.history.goBack();
    } else {
      Setting.redirectToLoginPage(this.getApplicationObj(), this.props.history);
    }
  }

  parseOffset(offset) {
      if (offset === 2 || offset === 4 || Setting.inIframe() || Setting.isMobile()) {
        return "0 auto";
      }
      if (offset === 1) {
        return "0 10%";
      }
      if (offset === 3) {
        return "0 60%";
      }
    }

  render() {
    const application = this.getApplicationObj();
    if (application === undefined) {
      return null;
    }
    if (application === null) {
      return Util.renderMessageLarge(this, this.state.msg);
    }

    return (
      <React.Fragment>
        <CustomGithubCorner />
        <CustomThemeContextProvider>
          <div className="login-content" style={{margin: this.props.preview ?? this.parseOffset(application.formOffset)}}>
            {Setting.inIframe() || Setting.isMobile() ? null : <div dangerouslySetInnerHTML={createMarkup(application.formCss)} />}
            {Setting.inIframe() || !Setting.isMobile() ? null : <div dangerouslySetInnerHTML={createMarkup(application.formCssMobile)} />}
            <div className="login-panel">
              <div className="side-image">
                  <SignupLeftWrapper>
                      {Setting.renderLogo(application)}
                      <div
                        className="side-image-container"
                        dangerouslySetInnerHTML={createMarkup(application.formSideHtml)}
                      />
                      <div className="flex flex-col mt-10">
                        <span className="signup-bottom-title">Connect with clients globally</span>
                        <span className="signup-bottom-sub-title">Join the future of cloud telephony for businesses</span>
                      </div>
                  </SignupLeftWrapper>
              </div>
              <div className="login-form-container flex justify-center">
                <div className="login-logo-box w-[413px]">
                  {
                    Setting.renderHelmet(application)
                  }
                  <ForgotPasswordFormWrapper
                    application={application}
                    onFinish={this.onFormFinishRef}
                    current={this.state.current}
                    setState={this.setStateRef}>
                    {this.renderForm(application)}
                  </ForgotPasswordFormWrapper>
                </div>
              </div>
            </div>
          </div>
        </CustomThemeContextProvider>
        <CrispChat />
      </React.Fragment>
    );
  }
}

export default withRouter(ForgotPageCustom);
