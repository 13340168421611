import * as yup from "yup";

import { inputs } from "src/krispcall/schema-validation";

export const newPasswordSchema = yup.object().shape({
  password: inputs.password,
    confirm: yup.string()
        .oneOf([yup.ref("password"), null], "Password must match")
        .required("Confirm Password is required"),
});
