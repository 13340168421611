// Copied from self forget page

import React from "react";

import ForgotPageCustom from "./ForgotPageCustom";

class SelfForgetPageCustom extends React.Component {

  render() {
    return (
      <ForgotPageCustom
        type={"forgotPassword"}
        applicationName={"web"} // setting default application to web/krispcall
        {...this.props}
      />
    );
  }
}

export default SelfForgetPageCustom;
